@layer base {
  .main {
  display: flex;
  flex-direction: column;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-24);
  background-color: var(--sk-color-grey-025);
  gap: var(--sk-space-48);
}

@media (--sk-medium-viewport) {
  .main {
    flex-direction: row;
    gap: var(--sk-space-56);
    padding: var(--sk-space-40) 0;
  }
}

.user,
.company {
  flex: 1;
}

@media (--sk-medium-viewport) {
  .user {
    padding: 0 var(--sk-space-40);
  }
}

.user .badges {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-12);
}

.user .badge {
  align-self: flex-start;
}

.user .badge::first-letter,
.user .badge ::first-letter {
  text-transform: uppercase;
}

@media (--sk-medium-viewport) {
  .user .badges {
    flex-direction: row;
  }
}

.user .title {
  margin-top: var(--sk-space-16);
  margin-bottom: var(--sk-space-40);
}

.user .avatar {
  align-self: flex-start;
}

.user .avatar img {
  width: auto;
}

.user .details {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-4);
}

.user .details .details___userContact {
  align-self: flex-start;
  margin-top: var(--sk-space-16);
}

.company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--sk-space-24);
  border-top: 2px solid var(--sk-color-grey-100);
  gap: var(--sk-space-8);
}

@media (--sk-medium-viewport) {
  .company {
    padding-top: 0;
    border-top: none;
    border-left: 2px solid var(--sk-color-grey-100);
  }
}

.company .illustration {
  margin-bottom: var(--sk-space-4);
  border-radius: var(--sk-radius-8);
}

}