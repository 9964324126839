@layer base {
  .main {
  margin-bottom: var(--sk-size-240);
}

.divider {
  margin: var(--sk-space-24) 0 var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .divider {
    margin: var(--sk-space-32) 0 var(--sk-space-48);
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (--sk-small-viewport-only) {
  .footer {
    flex-direction: column-reverse wrap;
  }
}

@media (--sk-small-viewport-only) {
  .filter:first-child {
    margin-top: var(--sk-space-24);
  }
}

.filter__location label,
.filter__location label span {
  font-size: var(--sk-typography-title-3-font-size);
  font-weight: var(--sk-typography-body-1-bold-font-weight);
  line-height: var(--sk-typography-title-3-line-height);
}

}