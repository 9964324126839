@layer base {
  .main {
  --local-row-gap: var(--sk-space-40) !important;
  margin-top: var(--sk-space-40);

  margin-bottom: var(--sk-space-40);
}

@media (--sk-medium-viewport) {
  .main {
    --local-row-gap: var(--sk-space-80) !important;
    margin-top: var(--sk-size-88);

    margin-bottom: var(--sk-size-88);
  }
}

/* Cards */
.cards {
  display: flex;
  flex-flow: column wrap;
  gap: var(--sk-space-16);
}

.card {
  margin: 0;
  padding: var(--sk-space-24);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-grey-050);
}

@media (--sk-medium-viewport) {
  .cards {
    flex-direction: row;
    align-content: space-between;
    gap: var(--sk-space-24);
  }

  .card {
    flex: 1;
  }
}

/* Header */
.header {
  text-align: center;
}

.header .title {
  margin-bottom: var(--sk-space-16);
}

.header .subtitle {
  margin-bottom: var(--sk-space-16);
}

.header .link {
  margin-top: var(--sk-space-24);
}

/* Figures */
.figures {
  margin: var(--sk-space-40) 0 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.figures strong {
  display: block;
}

/* Positions */
.positions .list {
  --local-column-count: 1;
  --local-gap: var(--sk-space-24);

  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);

  margin: var(--sk-space-24) 0 0;
  padding: 0;
  list-style: none;
  gap: var(--local-gap);
}

/* 480px */

@media (width > 30rem) {
  .positions .list {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-gap)), 1fr));
  }
}

@media (--sk-medium-viewport) {
  .positions .list {
    grid-template-columns: repeat(3, minmax(calc(33% - var(--local-gap)), 1fr));
  }
}

.companyCard {
  min-width: auto;
  height: 100%;
}

.companyCard .head {
  align-items: center;
  text-align: center;
}

.companyCard a {
  font-size: var(--sk-typography-body-1-semibold-font-size);
  line-height: var(--sk-typography-body-1-semibold-line-height);
}

/* Fun Fact */
.funFact {
  position: relative;
  padding: var(--sk-space-32) var(--sk-space-48);
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-purple-500);
  color: var(--sk-color-white);
}

.funFact::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--sk-size-48);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  background-image: url("../../assets/thumb-up-purple.svg");
  background-repeat: repeat;
  background-position: center right;
  background-size: calc(var(--sk-space-24) + var(--sk-space-8));
}

.funFact .image {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  user-select: none;
  rotate: -90deg;
}

/* Skills */
.skills .cards {
  margin-top: var(--sk-space-24);
}

.skills .card {
  display: flex;
  flex-flow: column wrap;
  gap: var(--sk-space-16);
}

.skills ul {
  margin: 0;
  padding: 0 0 0 var(--sk-space-20);
}

/* Insight experiences */

.insights .head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--sk-space-24);
  gap: var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .insights .head {
    flex-direction: row;
    align-items: center;
  }
}

.insights .list {
  --local-column-count: 1;
  display: grid;
  grid-template-columns: repeat(var(--local-column-count), 1fr);
  margin: 0;
  padding: 0;

  list-style-type: none;
  gap: var(--sk-space-16);
}

/* 480px */

@media (width > 30rem) {
  .insights .list {
    --local-column-count: 2;
  }
}

.insights .list > article,
.insights .insightCard {
  height: 100%;
}

.insights .insightCard {
  --sk-data-display-card-body-gap: var(--sk-space-24);
}

/* Job Ads */
.jobAds {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: var(--sk-space-48) 0;
  gap: var(--sk-space-24);
}

.jobAds--variant-light {
  background-color: var(--sk-color-grey-025);
}

.jobAds--variant-dark {
  background-color: var(--sk-color-black);
  color: var(--sk-color-white);
}

@media (--sk-medium-viewport) {
  .jobAds {
    gap: var(--sk-space-40);
  }
}

.jobAds::before {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  width: 100vw;
  margin-left: 50%;
  background-color: inherit;
  inset: 0;
  translate: calc(-50% - 1px);
}

.jobAds .head,
.jobAds .list {
  position: relative;
  z-index: var(--z-up-in-the-current-stacking-context);
}

.jobAds .head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--sk-space-16);
}

@media (--sk-medium-viewport) {
  .jobAds .head {
    flex-direction: row;
    align-items: center;
  }
}

.jobAds .head .title {
  margin-right: auto;
}

.jobAds .list {
  --local-gap: var(--sk-space-16);

  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: var(--local-gap);
}

@media (--sk-medium-viewport) {
  .jobAds .list {
    grid-template-columns: repeat(2, minmax(calc(50% - var(--local-gap)), 1fr));
  }
}

@media (--sk-large-viewport) {
  .jobAds .list {
    grid-template-columns: repeat(3, minmax(calc(33.33% - var(--local-gap)), 1fr));
  }
}

/* All job ads banner */
.allJobAdsBanner {
  position: relative;
  padding: var(--sk-space-80) 0;
}

.allJobAdsBanner::before {
  content: "";
  position: absolute;
  z-index: var(--z-reset-in-the-current-stacking-context);
  width: 100vw;
  margin-left: 50%;
  background-color: var(--sk-color-black);
  inset: 0;
  translate: calc(-50% - 1px);
}

.allJobAdsBanner .content {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: var(--sk-space-32) var(--sk-space-48);
  overflow: hidden;
  border-radius: var(--sk-radius-16);
  background-color: var(--sk-color-white);
  gap: var(--sk-space-8);
}

.allJobAdsBanner .content::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: var(--sk-size-48);
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  background-image: url("../../assets/search.svg");
  background-repeat: repeat;
  background-position: center right;
  background-size: calc(var(--sk-space-24) + var(--sk-space-8));
}

.allJobAdsBanner .image {
  position: absolute;
  top: calc(-1 * var(--sk-space-72));
  right: calc(-1 * var(--sk-space-80));
  pointer-events: none;
  scale: 0.5;
  user-select: none;
}

@media (--sk-medium-viewport) {
  .allJobAdsBanner .image {
    top: calc(-1 * var(--sk-space-40));
    right: calc(-1 * var(--sk-space-72));
    scale: 1;
  }
}

.allJobAdsBanner a {
  margin-top: var(--sk-space-8);
}

/* Feedback */
.feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sk-space-24) var(--sk-space-16);
  border: var(--sk-border-width-2) solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  gap: var(--sk-space-24);
}

.feedback .title {
  margin: 0 auto 0 0;
}

.feedback .title:first-child:last-child {
  width: 100%;
  text-align: center;
}

/* Breadcrumb */
.breadcrumb {
  display: flex;
  justify-content: center;
}

}