@layer base {
  .main {
  display: flex;
  flex-direction: column;
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-size-40);
  gap: var(--sk-space-48);
}

@media (--sk-medium-viewport) {
  .main {
    padding-top: var(--sk-size-88);
    padding-bottom: var(--sk-size-88);
    gap: var(--sk-space-64);
  }
}

.commonHeader {
  --local-row-gap: var(--sk-space-40) !important;
}

/* Head */
.head {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-16);
}

.head__logo {
  border-radius: var(--sk-Avatar-border-radius);
  outline: 1px solid var(--sk-color-grey-100);
}

.head__title {
  flex: 1;
}

.head__search {
  width: 100%;
}

@media (--sk-medium-viewport) {
  .head {
    flex-direction: row;
    gap: var(--sk-space-24);
  }

  .head__search {
    width: var(--sk-size-320);
  }
}

}