@layer base {
  .main {
  position: relative;
}

.main::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  background: linear-gradient(90deg, rgb(246 246 249 / 0%) 20%, #ffffff 100%);
}

.wrapper {
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: var(--sk-space-24);
}

.link {
  display: flex;
  align-items: center;
  padding-bottom: var(--sk-space-16);
  color: var(--sk-color-black);
  text-decoration: none;
  white-space: nowrap;
}

.link:hover,
.link__current {
  border-bottom: 4px solid #000000;
}

.link__current {
  font-weight: 600;
}

}