@layer base {
  .main {
  --local-column-gap: var(--sk-space-24);
  padding-top: var(--sk-space-40);
  padding-bottom: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .main {
    padding-top: var(--sk-size-88);
  }
}

.header {
  display: flex;
  gap: var(--sk-space-24);
  flex-direction: column;
}

}